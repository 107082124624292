let artSelect = {

	elDeleteItem: `
<div class="item">
	#V#
	<button class="" onclick="return artSelect.onDeleteItem(event,#K#)" title="Удалить выбранный елемент">x</button>
</div>
`,

	clear: function (el) {
		el = typeof el === "string" ? I(el) : el;
		if (!el) return;
		// console.log("clear el", el, el.A("placeholder"));
		el.innerHTML = el.A("placeholder");
		el.setA("value","");

		let dl = el.previousElementSibling;
		if (!dl) return;
		let url = dl.A("selfref");
		url = url.setURLParam("exc","");
		dl.setA("selfref", url);
	},

	onDeleteItem: (event, val) => {
		// console.log("onDeleteItem",event,val);

		let elDeleteItem = event.target.parentNode;
		let elA = elDeleteItem.parentNode;
		let elDl = elA.previousSibling;

		let url = elDl.A("selfref");

		elDeleteItem.remove();

		let value = elA.A("value");
		value = value ? JSON.parse(value) : [];

		let index = 0;
		for (let i = value.length; i >= 0; i--) {
			if (value[i] == val) {
				index = i;
				break;
			}
		}
		// console.log("value.indexOf(val)",value.indexOf(val), value, val, index);
		value.splice(index, 1);

		if (!value.length) {
			elA.innerHTML = elA.A("placeholder");
		}

		elA.setA("value", JSON.stringify(value));

		url = url.setURLParam("exc", value.join(","));
		elDl.setA("selfref", url);

		if (elA.hasA("ondelete")) {
			new Function("value", elA.A("ondelete")).call(this, val);
		}

		event.stopPropagation();
		event.preventDefault();
		return false;
	},

	choose: function (event, elRow, vueObj) {
		// console.log("choose", event, event.target.UA("modal"), typeof event.target);
		let modal = elRow.UA("modal");
		let url = modal.A("selfref");

		if (!elRow.hasA("key")) throw new Error("require to define 'key' attribute");
		if (!elRow.hasA("value")) throw new Error("require to define 'value' attribute");

		let currKey = elRow.A("key");
		let currValue = elRow.hasA("value") ? elRow.A("value") : currKey;

		let elA = modal.nextSibling;
		// console.log("elA", elA);

		if (elA.hasA('multiple')) {
			let value = elA.A("value");
			value = value ? JSON.parse(value) : [];
			// console.log("value", value);

			let elDeleteItem = artSelect.elDeleteItem.replace("#V#", currValue).replace("#K#", currKey);
			if (value.length) {
				elA.innerHTML += elDeleteItem;
			} else {
				elA.innerHTML = elDeleteItem;
			}

			value.push(currKey);

			elA.setA("value", JSON.stringify(value));

			url = url.setURLParam("exc", value.join(","));
		} else {
			elA.innerHTML = currValue;
			elA.setA("value", currKey);
			url = url.setURLParam("exc", currKey);
		}

		modal.setA("selfref", url);
		modal.closeModal();

		if (elA.hasA("onchoose")) {
			new Function("key","value", elA.A("onchoose")).call(this, currKey, currValue);
		}

		event.stopPropagation();
		event.preventDefault();
		return false;
	},

	onChoose: (element, def, vueObj) => {
		console.log("onChoose", element, def, vueObj);
		let selectEl = element.previousSibling.previousSibling;

		if (selectEl.hasA('many')) {
			let btnDeleteItem = artSelect.btnDeleteItem.replace("#", def.rid);
			if (selectEl.innerHTML.trim()) {
				selectEl.innerHTML += def.currValue + btnDeleteItem;
			} else {
				selectEl.innerHTML = def.text + btnDeleteItem;
			}

			let val = JSON.parse(selectEl.A("value"));
			let many = selectEl.A("many");
			console.log("val", val);
			console.log("many", many);

			val.push(def.rid);
			let url = element.A("selfref");
			console.log("url b", url);
			url.setURLParam("test", def.rid);
			console.log("url a", url);
			element.setA("selfref", url);

			if (vueObj) {

			}
		} else {
			selectEl.innerHTML = def.text;
			selectEl.setA("value", def.rid);

			if (vueObj && selectEl.hasA("v-model")) {
				let pathToVar = selectEl.A("v-model").split(".");
				let v = vueObj;
				for (let i = 0; i < (pathToVar.length - 1); i++) {
					v = v[pathToVar[i]];
				}
				v[pathToVar[pathToVar.length-1]] = def.rid;
			}
		}

		// selectEl.previousSibling.value = def.rid;
	}

};

window.artSelect = artSelect;